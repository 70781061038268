import React, { useState, useEffect } from 'react';

var quizdata = [
    {
        id: 1,
        question: "Choose the fruits among the following:",
        audio: "assets/audio/questions/question-one.mp3",
        options: {
            a: "cherry",
            b: "apple",
            c: "cucumber",
            d: "banana"
        },
        answers: ["a", "b", "d"],
        wrongs: {
            c: "cucumber is not a fruit, it's a vegetable"
        }
    },
    {
        id: 2,
        question: "Where was the kiwi fruit first grown?",
        audio: "assets/audio/questions/question-two.mp3",
        options: {
            a: "New Zealand",
            b: "China",
            c: "Australia",
            d: "Chile",
        },
        answers: ["a"],
        wrongs: {
            a: "New Zealand",
            c: "Australia",
            d: "Chile",
        },
    },
    {
        id: 3,
        question: "Which fruit has the highest oil content?",
        audio: "assets/audio/questions/question-three.mp3",
        options: {
            a: "Peach",
            b: "Avocado",
            c: "Olive",
            d: "Mango"
        },
        answers: ["c"],
        wrongs: {
            a: "Peach",
            b: "Avocado",
            d: "Mango"
        }
    },
    {
        id: 4,
        question: "What percentage of the watermelon is water?",
        audio: "assets/audio/questions/question-four.mp3",
        options: {
            a: "34",
            b: "80",
            c: "66",
            d: "92"
        },
        answers: ["d"],
        wrongs: {
            a: "34",
            b: "80",
            c: "66",
        }
    },
    {
        id: 5,
        question: "The flower of the cherry tree is the national symbol of which country?",
        audio: "assets/audio/questions/question-five.mp3",
        options: {
            a: "India",
            b: "Canada",
            c: "China",
            d: "France"
        },
        answers: ["c"],
        wrongs: {
            a: "India",
            b: "Canada",
            d: "France"
        }
    },
];

const Home = () => {
    const [quiz, setQuiz] = useState(quizdata); // Replace [...] with your quiz data
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [userAnswers, setUserAnswers] = useState({});
    const [score, setScore] = useState(0);
    const [feedback, setFeedback] = useState('');
    const [firstAttempt, setFirstAttempt] = useState(true);

    const handleCheckboxChange = (option) => {
        setUserAnswers((prevAnswers) => ({
            ...prevAnswers,
            [currentQuestion]: {
                ...prevAnswers[currentQuestion],
                [option]: !prevAnswers[currentQuestion]?.[option],
            },
        }));
    };

    const checkAnswer = () => {
        const selectedOptions = Object.keys(userAnswers[currentQuestion] || {});
        const correctOptions = quiz[currentQuestion].answers;

        const isCorrect = selectedOptions.sort().toString() === correctOptions.sort().toString();

        setFirstAttempt(false);

        if (isCorrect) {
            setScore((prevScore) => prevScore + 1);
            setFeedback('Correct!');
        } else {
            setFeedback('Incorrect!');
        }
    };

    const nextQuestion = () => {
        if (currentQuestion < quiz.length - 1) {
            setCurrentQuestion((prevQuestion) => prevQuestion + 1);
            setFeedback('');
            setFirstAttempt(true);
            checkAnswer();
        }
    };

    const prevQuestion = () => {
        if (currentQuestion > 0) {
            setCurrentQuestion((prevQuestion) => prevQuestion - 1);
            setFeedback('');
            setFirstAttempt(true);
        }
    };

    const finishQuiz = () => {
        alert(`You had ${score} correct questions out of ${quiz.length}`);
    };

    useEffect(() => {
    }, [currentQuestion, score]);

    return (
        <div className="container-fluid ">
            <div className="row">
                <div className="col-xs-12 col-md-12">
                    <div className="row">

                        <div className="questContainer">
                            <div className="question">
                                <p>{quiz[currentQuestion].question}</p>

                                {
                                    quiz.map((item, index) => (
                                        item.audio && currentQuestion == index && (
                                            <audio controls autoPlay>
                                                <source src={item.audio} type="audio/mp3" />
                                                Your browser does not support the audio element.
                                            </audio>
                                        )
                                    ))
                                }


                                <div className="options">
                                    {Object.entries(quiz[currentQuestion].options).map(([option, label]) => (
                                        <label key={option} >
                                            <input
                                                type="checkbox"
                                                checked={userAnswers[currentQuestion]?.[option] || false}
                                                onChange={() => handleCheckboxChange(option)}
                                                style={{ marginRight: "10px" }}
                                            />
                                            {`${option} - ${label}`}
                                        </label>
                                    ))}
                                </div>
                            </div>

                            <div className="feedback">
                                {feedback && (
                                    <>
                                        <p>{feedback}</p>
                                    </>
                                )}
                            </div>



                            <div className="row">
                                <div className="interact">

                                    <div className="navbuttons">
                                        <div className="col-xs-10 col-xs-offset-1 col-md-8 col-md-offset-2" style={{ display: "flex", justifyContent: "space-around", flexDirection: "row" }}>
                                            <button onClick={prevQuestion} style={{ width: "fit-content" }} className="btn btn-info btn-block next">
                                                Previous
                                            </button>

                                            {/* <button onClick={checkAnswer} style={{ width: "fit-content" }} className="btn btn-info btn-block next">
                        Check
                      </button> */}


                                            {currentQuestion === quiz.length - 1 ? (
                                                <button onClick={finishQuiz} style={{ width: "fit-content" }} className="btn btn-info btn-block next">
                                                    Finish
                                                </button>
                                            ) : (
                                                <button onClick={nextQuestion} style={{ width: "fit-content" }} className="btn btn-info btn-block next">
                                                    Next
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;


