import React, { useState, useEffect } from 'react';

const exercises = [
    {
        question: "What is the scientific name for the apple tree?",
        image: "assets/images/fruits/apple-icon.gif",
        answer: ["Malus domestica"],
        list: ["Malus domestica", "Pyrus malus", "Prunus avium", "Citrus sinensis"],
    },
    {
        question: "Choose the fruits among the following",
        image: "assets/images/fruits/fruit.gif",
        answer: ["cherry", "apple", "banana"],
        list: ["cherry", "apple", "cucumber", "banana"],
    },
    {
        question: "Where was the kiwi fruit first grown?",
        image: "assets/images/fruits/kiwi-fruit.gif",
        answer: ["New Zealand"],
        list: ["New Zealand", "China", "Australia", "Chile"],
    },
    {
        question: "Which fruit has the highest oil content?",
        image: "assets/images/fruits/olive.gif",
        answer: ["Olive"],
        list: ["Peach", "Avocado", "Olive", "Mango"],
    },
    {
        question: "What percentage of the watermelon is water?",
        image: "assets/images/fruits/water-melon.gif",
        answer: ["92"],
        list: ["34", "80", "66", "92"],
    },
];

const HomeThree = () => {
    const synth = window.speechSynthesis;

    const [currentIndex, setCurrentIndex] = useState(0);
    const [exercise, setExercise] = useState(exercises[currentIndex]);
    const [selectedWord, setSelectedWords] = useState([]);
    const [isAnswerCorrect, setIsAnswerCorrect] = useState(null);
    const [modalShow, setModalShow] = useState(false);

    const [selectedLanguage, setSelectedLanguage] = useState('');

    const handleLanguageChange = (event) => {
        setSelectedLanguage(event.target.value);
    };


    const handleSpeak = (text) => {
        if (synth.speaking) {
            console.error('SpeechSynthesisUtterance is already speaking');
            return;
        }

        const utterance = new SpeechSynthesisUtterance(text);
        synth.speak(utterance);
    };


    const [exercisesState, setExercisesState] = useState(
        exercises.map(() => ({ selectedWords: [], isAnswerCorrect: null }))
    );

    const handleWordClick = (index) => {
        setExercisesState((prevExercisesState) => {
            const updatedExercisesState = [...prevExercisesState];
            updatedExercisesState[currentIndex] = {
                selectedWords: [...updatedExercisesState[currentIndex].selectedWords, exercises[currentIndex].list[index]],
                isAnswerCorrect: null,
            };
            return updatedExercisesState;
        });
    };

    const handleRemoveWordClick = (index) => {

        setExercisesState((prevExercisesState) => {
            const updatedExercisesState = [...prevExercisesState];
            const updatedSelectedWords = [...updatedExercisesState[currentIndex].selectedWords];
            updatedSelectedWords.splice(index, 1);
            updatedExercisesState[currentIndex] = {
                selectedWords: updatedSelectedWords,
                isAnswerCorrect: null,
            };
            return updatedExercisesState;
        });

        // const updatedSelectedWords = [...selectedWord];
        // updatedSelectedWords.splice(index, 1);
        // setSelectedWords(updatedSelectedWords);
    };

    const handleCheck = () => {
        const isCorrect = JSON.stringify(exercisesState[currentIndex].selectedWords) === JSON.stringify(exercises[currentIndex].answer);
        setIsAnswerCorrect(isCorrect);
        setModalShow(true);
        setExercisesState((prevExercisesState) => {
            const updatedExercisesState = [...prevExercisesState];
            updatedExercisesState[currentIndex] = {
                selectedWords: exercisesState[currentIndex].selectedWords,
                isAnswerCorrect: isCorrect,
            };
            return updatedExercisesState;
        });

        if (currentIndex === exercises.length - 1) {
            // If it's the last question, show the score in an alert
            const score = calculateScore();
            // alert(`Your score: ${score}`);
        } else {
            setTimeout(() => {
                setCurrentIndex((prevIndex) => prevIndex + 1);
                handleSpeak(exercises[currentIndex + 1].question)
                setIsAnswerCorrect(null);
            }, 3100);
        }
        setTimeout(() => {
            setModalShow(false);
        }, 3000);
    };

    const handleSkip = () => {

        if (currentIndex === exercises.length - 1) {
            // If it's the last question, show the score in an alert
            const score = calculateScore();
            alert(`Your score: ${score}`);
        } else {
            // Move to the next question
            setCurrentIndex((prevIndex) => prevIndex + 1);
            handleSpeak(exercises[currentIndex + 1].question)
        }
        setExercisesState((prevExercisesState) => {
            const updatedExercisesState = [...prevExercisesState];
            updatedExercisesState[currentIndex] = {
                selectedWords: exercisesState[currentIndex].selectedWords,
                isAnswerCorrect: false,
            };
            return updatedExercisesState;
        });
        setIsAnswerCorrect(null);
    };

    const calculateScore = () => {
        // Implement your scoring logic here
        // For example, count the number of correct answers
        // const correctAnswers = exercisesState.filter(
        //     (exercise) =>
        //         JSON.stringify(exercise.selectedWords) ===
        //         JSON.stringify(exercises[exercisesState.indexOf(exercise)].answer)
        // );

        const correctAnswers = exercisesState.filter((exercise) => exercise.isAnswerCorrect === true);
        return correctAnswers.length;

    };

    useEffect(() => {
        setExercise(exercises[currentIndex]);
        setSelectedWords([]);
        setIsAnswerCorrect(null);
    }, [currentIndex]);

    const RendorSelectedWord = (selectedWord) => {
        return (
            selectedWord.map((word, index) => (
                <div
                    className="word"
                    onClick={() => handleRemoveWordClick(index)}
                >
                    {word}
                </div>
            ))
        )
    }

    return (
        <div>

            <header>
                <div className="left">
                    <img src="assets/images/profiles/muazzim.png" alt="Muazzim Hussain" className='profile-pic' />
                    <p>Muazzim Hussain</p>
                </div >
                <div className="right">

                    <div>
                        {/* <p htmlFor="languageSelect">{selectedLanguage}</p> */}
                        <select
                            id="languageSelect"
                            value={selectedLanguage}
                            onChange={handleLanguageChange}
                        >
                            <option value="en">EN</option>
                            <option value="es">ES</option>
                            <option value="fr">FR</option>
                            {/* Add more language options as needed */}
                        </select>
                    </div>

                    <img src="assets/images/icons/translate.gif" alt="Transalate" className='translate-pic' />
                </div>
            </header>

            <div className="wrapper">
                {/* <h1>Qcodes Infotech</h1> */}

                <div className='stepper-container'>
                    <div className="stepper">
                        {exercisesState.map((exercise, index) => (
                            <div
                                key={index}
                                className={`stepper-indicator ${exercise.isAnswerCorrect === true
                                    ? 'correct'
                                    : exercise.isAnswerCorrect === false
                                        ? 'wrong'
                                        : ''
                                    }`}
                            />
                        ))}
                    </div>

                    <div className='mark-container'>
                        <p>{calculateScore()}</p>
                        <img src="assets/images/icons/trophy.gif" alt="trophy" className='trophy-img' />
                    </div>
                </div>

                <div className="top__container">
                    <div className="image_text__container">
                        <div className='image-container'>
                            <img src="assets/images/icons/sound.gif" alt="sound" className='speech-img' onClick={() => { handleSpeak(exercises[currentIndex].question) }} />
                            <img src={exercises[currentIndex].image} alt="apple" className='question-image' />
                        </div>
                        <p id="original__text">
                            <span>{exercises[currentIndex].question}</span>
                        </p>
                    </div>
                    <div id="destination__container">
                        {RendorSelectedWord(exercisesState[currentIndex].selectedWords)}
                    </div>
                </div>

                <div id="origin__container">
                    {exercises[currentIndex].list.map((word, index) => (
                        !exercisesState[currentIndex].selectedWords.includes(word) && (
                            <button key={index} className="word" onClick={() => handleWordClick(index)}>
                                {word}
                            </button>
                        )
                    ))}
                </div>
            </div>

            <footer>
                <div className="skip_button" onClick={handleSkip}>
                    SKIP
                </div>
                <div
                    className="answer_feedback"
                    style={{ visibility: isAnswerCorrect !== null ? "visible" : "hidden" }}>
                    {isAnswerCorrect ? 'Correct!' : 'Wrong!'}
                </div>
                <div className="check_button" onClick={handleCheck}>
                    {currentIndex === exercises.length - 1 ? "SUBMIT" : "VERIFY"}
                </div>

            </footer>

            <div id="loom-companion-mv3" ext-id="liecbddmkiiihnedobmlmillhodjkdmb">
                <section id="shadow-host-companion" />
            </div>

            <div className="modal" style={{ display: modalShow ? "flex" : "" }}>
                {
                    isAnswerCorrect ?
                        <img src="assets/images/icons/success.gif" alt="Your Image" />
                        : <img src="assets/images/icons/wrong.gif" alt="Your Image" />
                }
            </div>

        </div>
    );
};

export default HomeThree;
