import React, { useState, useEffect } from 'react';

const exercises = [
    {
        question: "Apple",
        image: "assets/images/fruits/images/apple-icon.png",
    },
    {
        question: "Orange",
        image: "assets/images/fruits/images/orange-icon.png",
    },
    {
        question: "Banana",
        image: "assets/images/fruits/images/banana-icon.png",
    },
    {
        question: "Mango",
        image: "assets/images/fruits/images/mango-icon.png",
    },
    {
        question: "Watermelon",
        image: "assets/images/fruits/images/water-melon-icon.jpeg",
    },
];

const Learning = () => {
    const synth = window.speechSynthesis;
    const [currentIndex, setCurrentIndex] = useState(0);
    const [selectedLanguage, setSelectedLanguage] = useState('');

    const handleLanguageChange = (event) => {
        setSelectedLanguage(event.target.value);
    };

    const handleSpeak = (text) => {
        if (synth.speaking) {
            console.error('SpeechSynthesisUtterance is already speaking');
            return;
        }

        const utterance = new SpeechSynthesisUtterance(text);
        synth.speak(utterance);
    };

    const handleCheck = () => {

        if (currentIndex === exercises.length - 1) {
            window.location.href = "/test"
        } else {
            setCurrentIndex((prevIndex) => prevIndex + 1);
            handleSpeak(exercises[currentIndex + 1].question)
        }
    };

    return (
        <div>

            <header>
                <div className="left">
                    <img src="assets/images/profiles/muazzim.png" alt="Muazzim Hussain" className='profile-pic' />
                    <p>Muazzim Hussain</p>
                </div >
                <div className="right">

                    <div>
                        {/* <p htmlFor="languageSelect">{selectedLanguage}</p> */}
                        <select
                            id="languageSelect"
                            value={selectedLanguage}
                            onChange={handleLanguageChange}
                        >
                            <option value="en">EN</option>
                            <option value="es">ES</option>
                            <option value="fr">FR</option>
                            {/* Add more language options as needed */}
                        </select>
                    </div>

                    <img src="assets/images/icons/translate.gif" alt="Transalate" className='translate-pic' />
                </div>
            </header>

            <div className="wrapper">
                {/* <h3>Learning</h3> */}
                <div className='stepper-container'>
                    <div className="stepper">
                        {exercises.map((exercise, index) => (
                            <div
                                key={index}
                                className={`stepper-indicator ${index < currentIndex ? 'correct' : ''}`}
                            />
                        ))}
                    </div>
                </div>

                <div className="top__container">
                    <div className="image_text__container">
                        <div className='image-container'>
                            <img src="assets/images/icons/sound.gif" alt="sound" className='speech-img' onClick={() => { handleSpeak(exercises[currentIndex].question) }} />
                            <img src={exercises[currentIndex].image} alt="apple" className='question-image' />
                        </div>
                        <p id="original__text">
                            <span>{exercises[currentIndex].question}</span>
                        </p>
                    </div>
                </div>
                <div></div>
            </div>

            <footer>
                <div className="check_button" onClick={handleCheck}>
                    {currentIndex === exercises.length - 1 ? "TEST" : "NEXT"}
                </div>
            </footer>
        </div>
    );
};

export default Learning;
