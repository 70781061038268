import React, { useState, useEffect } from 'react';
var exercises = [
    {
        english: "I eat an apple",
        answer: ["Yo", "como", "una", "manzana"],
        list: ["manazana", "comes", "Yo", "una", "como", "naranja"],
    },
    {
        english: "This is my wife",
        answer: ["Este", "es", "mi", "esposa"],
        list: ["Este", "esta", "esposa", "es", "mi", "hermana"],
    },
    {
        english: "My favorite sport is hockey",
        answer: ["Mi", "deporte", "favorito", "es", "hockey"],
        list: ["deporte", "favorito", "hockey", "Mi", "es", "Tu"],
    },
]

const HomeTwo = () => {

    const [exercise, setExercise] = useState(exercises[Math.floor(Math.random() * exercises.length)]);
    const [listOfWords, setListOfWords] = useState(exercise.list);
    const [selectedWord, setSelectedWords] = useState([]);

    // Function to handle word click
    const handleWordClick = (index) => {
        setSelectedWords(prevSelectedWords => [...prevSelectedWords, listOfWords[index]]);
    };

    const handleRemoveWordClick = (index) => {
        const updatedSelectedWords = [...selectedWord];
        updatedSelectedWords.splice(index, 1);
        setSelectedWords(updatedSelectedWords);
    };

    useEffect(() => {
        if (selectedWord) {
            console.log(selectedWord)
        }
    }, [selectedWord])



    const RendorSelectedWord = (selectedWord) => {
        return (
            selectedWord.map((word, index) => (
                <div
                    className="word"
                    onClick={() => handleRemoveWordClick(index)}
                >
                    {word}
                </div>
            ))
        )
    }

    return (
        <div>

            <div className="wrapper">
                <h1>Qcodes Infotech</h1>
                <div className="top__container">
                    <div className="image_text__container">
                        <img src="assets/images/fruits/apple-icon.png" alt="apple" style={{ width: "50px" }} />
                        <p id="original__text">
                            <span>I</span>
                            <span>eat</span>
                            <span>an</span>
                            <span>apple</span>
                        </p>
                    </div>
                    <div id="destination__container">
                        {RendorSelectedWord(selectedWord)}
                    </div>
                </div>
                <div id="origin__container">
                    {listOfWords.map((word, index) => (
                        !selectedWord.includes(word) && <button
                            key={index}
                            className="word"
                            onClick={() => handleWordClick(index)}
                        >
                            {word}
                        </button>
                    ))}
                </div>
            </div>
            <footer>
                <div className="skip_button">SKIP</div>
                <div className="check_button">CHECK</div>
            </footer>
            <div id="loom-companion-mv3" ext-id="liecbddmkiiihnedobmlmillhodjkdmb">
                <section id="shadow-host-companion" />
            </div>
        </div>
    )
}

export default HomeTwo