import React from 'react'
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Home from './component/home/Home'
import HomeTwo from './component/home/HomeTwo'
import HomeThree from './component/home/HomeThree'
import Learning from './component/learning/Learning';
import Test from './component/learning/Test';

const App = () => {
  return (
    <div>
      <Router>
        <Routes>
          <Route
            exact
            path="/home-one"
            element={<Home />}
          />
          <Route
            exact
            path="/home-three"
            element={<HomeThree />}
          />
          <Route
            exact
            path="/"
            element={<Learning />}
          />
          <Route
            exact
            path="/test"
            element={<Test />}
          />
        </Routes>
      </Router>
    </div>
  )
}

export default App